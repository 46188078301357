import React from "react";
import { Router } from "@reach/router";
import IndustriesPage from "../components/IndustriesPage";
import NotFoundPage from "./404";
import PathContainer from "../containers/PathContainer";

const industriesPageRouter = (props) => (
  <PathContainer paths={["/industries"]}>
    {({ isAllowed }) => (
      <Router>
        {!isAllowed ? (
          <NotFoundPage default />
        ) : (
          <IndustriesPage {...props} path="/industries" />
        )}
      </Router>
    )}
  </PathContainer>
);

export default industriesPageRouter;
