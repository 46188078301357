import PropTypes from "prop-types";
import React from "react";
import { StaticQuery, graphql } from "gatsby";

const IndustriesContainer = ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        allIndustriesJson {
          edges {
            node {
              id
              pageId
              pageTitle
              pageBanner {
                backgroundImage
                hideSection
                textContent {
                  content
                  heading
                }
              }
              introduction {
                actionButtons {
                  buttonLabel
                  buttonPath
                  buttonType
                  externalLink
                  hideButton
                  simpleLink
                }
                images {
                  image
                }
                hideSection
                textContent {
                  content
                  heading
                }
              }
              ourIndustries {
                hideSection
                textContent {
                  heading
                  content
                }
                actionButtons {
                  hideButton
                  simpleLink
                  externalLink
                  buttonType
                  buttonLabel
                  buttonPath
                }
              }
              ourClients {
                hideSection
                textContent {
                  content
                  heading
                }
              }
            }
          }
        }
        allIndustriesListJson(
          filter: { title: { ne: "DEFAULT" } }
          sort: { fields: title, order: ASC }
        ) {
          edges {
            node {
              title
              hideIndustry
              summary {
                heading
                content
                actionButtons {
                  hideButton
                  simpleLink
                  externalLink
                  buttonType
                  buttonLabel
                  buttonPath
                }
              }
              ctaSection {
                hideSection
                textContent {
                  heading
                  content
                }
                actionButtons {
                  hideButton
                  simpleLink
                  externalLink
                  buttonType
                  buttonLabel
                  buttonPath
                }
              }
              heroContent {
                actionButtons {
                  hideButton
                  simpleLink
                  externalLink
                  buttonType
                  buttonLabel
                  buttonPath
                }
                content
                heading
                image
              }
            }
          }
        }
        allAboutJson {
          edges {
            node {
              id
              pageId
              ourApproach {
                hideSection
                textContent {
                  heading
                  content
                }
                artworkImage
                approaches {
                  content
                }
                actionButtons {
                  hideButton
                  simpleLink
                  externalLink
                  buttonType
                  buttonLabel
                  buttonPath
                }
              }
            }
          }
        }
        allClientsJson {
          edges {
            node {
              id
              pageTitle
              textContent {
                heading
                content
              }
              clients {
                logo
                clientName
                clientUrl
                testimonial
                authorName
                authorDesignation
              }
            }
          }
        }
      }
    `}
    render={({
      allIndustriesJson: { edges: industriesData },
      allAboutJson: { edges: aboutData },
      allClientsJson: { edges: clientsData },
      allIndustriesListJson: { edges: industriesListData },
    }) =>
      children(
        industriesData.map((e) => ({
          ...e.node,
        })),
        aboutData.map((e) => ({
          ...e.node,
        })),
        clientsData.map((e) => ({
          ...e.node,
        })),
        industriesListData.map((e) => ({
          ...e.node,
        }))
      )
    }
  />
);

IndustriesContainer.propTypes = {
  children: PropTypes.func.isRequired,
};

export default IndustriesContainer;
