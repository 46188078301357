import { scroller } from "react-scroll";

const scrollToSection = (sectionName, options) => {
  scroller.scrollTo(sectionName, {
    duration: 550,
    offset: 0,
    smooth: "easeInOutQuad",
    ...options,
  });
};

export default scrollToSection;
