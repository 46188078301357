import React from "react";
import { graphql, StaticQuery } from "gatsby";
import PropTypes from "prop-types";

const PathContainer = ({ children, paths }) => (
  <StaticQuery
    query={graphql`
      query {
        allPagesJson {
          edges {
            node {
              id
              pages {
                url
                hidePage
              }
            }
          }
        }
      }
    `}
    render={({ allPagesJson: { edges: pagesData } }) => {
      const { pages } = pagesData.find((item) => item.node.id === "pages").node;
      return children({
        isAllowed: pages
          .map((p) => !p.hidePage && p.url)
          .some((i) => paths.includes(i)),
      });
    }}
  />
);

PathContainer.propTypes = {
  children: PropTypes.func.isRequired,
  paths: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default PathContainer;
