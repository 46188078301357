import React from "react";
import PropTypes from "prop-types";
import { camelCase } from "change-case";
import Box from "../../base/Box";
import MarkdownToHtml from "../MarkdownToHtml";
import LinkTo from "../LinkTo";
import Button from "../Button";
import AspectRatio from "../AspectRatio";
import Image from "../Image";
import Section from "../Section";
import Heading from "../Heading";

const CardsGrid = ({
  sectionSx,
  sectionTitle,
  headingSx,
  contentSx,
  sectionTitleSx,
  data,
  containerSx,
  imageSx,
  hashName,
}) => (
  <Section
    containerSx={{
      display: "flex",
      flexDirection: "column",
      maxWidth: [
        null,
        "maxWidths.sm",
        "maxWidths.md",
        "maxWidths.lg",
        "maxWidths.xl",
      ],
      ...containerSx,
    }}
    hashName={hashName}
    sx={{ bg: "white", mb: 0, mt: 0, ...sectionSx }}
  >
    {sectionTitle && (
      <Heading
        sx={{
          color: "primary",
          fontFamily: "secondary",
          fontSize: 2,
          fontWeight: "bold",
          letterSpacing: 4,
          lineHeight: 3,
          textAlign: "center",
          textTransform: "uppercase",
          ...sectionTitleSx,
        }}
      >
        {sectionTitle}
      </Heading>
    )}
    <Box sx={{ display: "flex", flexDirection: "column", mt: 4 }}>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          mx: "-1.5rem",
        }}
      >
        {data &&
          data.map((d, index) => {
            const title = d.title && camelCase(d.title);
            return (
              <Box
                key={`${title}-${index}`}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  maxWidth: [
                    "100%",
                    "calc(100% / 2)",
                    null,
                    null,
                    "calc(100% / 3)",
                  ],
                  p: "1.5rem",
                }}
              >
                <LinkTo
                  hoverSx={{
                    bg: "primarySoft",
                    boxShadow: 2,
                    button: {
                      bg: "primary",
                      color: "white",
                    },
                  }}
                  sx={{
                    bg: "white",
                    boxShadow: 1,
                    button: {
                      bg: "transparent",
                      color: "primary",
                    },
                    display: "flex",
                    flex: "auto",
                    flexDirection: "column",
                    pb: 6,
                    position: "relative",
                    transition: "all .45s",
                  }}
                  to={d.to ? d.to : "/"}
                >
                  {d.image && <Image img={d.image} sx={{ ...imageSx }} />}
                  <MarkdownToHtml
                    heading={d.heading && d.heading}
                    sx={{
                      bg: "primary",
                      h3: {
                        border: ".4rem solid",
                        borderColor: "primaryLight",
                        borderWidth: "0 0 0 0.8rem",
                        color: "text.primary",
                        fontSize: 0,
                        mb: 3,
                        ml: -3,
                        pl: 3,
                        py: 1,
                      },
                      minHeight: ["10rem", "25rem", null, "20rem"],
                      px: 3,
                      py: 4,
                      ...headingSx,
                    }}
                  />
                  <MarkdownToHtml
                    source={
                      d.content &&
                      (d.content.length >= 300
                        ? `${d.content.substring(0, 300)}...`
                        : d.content)
                    }
                    sx={{ p: 3, ...contentSx }}
                  />
                  <Button
                    sx={{
                      bottom: 0,
                      position: "absolute",
                      right: 0,
                      transition: "all .45s",
                    }}
                  >
                    <AspectRatio sx={{ color: "inherit", width: "2.4rem" }}>
                      <Image svgIcon="arrow-right" />
                    </AspectRatio>
                  </Button>
                </LinkTo>
              </Box>
            );
          })}
      </Box>
    </Box>
  </Section>
);

CardsGrid.propTypes = {
  containerSx: PropTypes.shape({}),
  contentSx: PropTypes.shape({}),

  data: PropTypes.shape({
    content: PropTypes.string,
    heading: PropTypes.string,
    image: PropTypes.string,
    to: PropTypes.string,
  }).isRequired,
  hashName: PropTypes.string,
  headingSx: PropTypes.shape({}),
  imageSx: PropTypes.shape({}),
  sectionSx: PropTypes.shape({}),
  sectionTitle: PropTypes.string,
  sectionTitleSx: PropTypes.shape({}),
};

CardsGrid.defaultProps = {
  containerSx: {},
  contentSx: {},
  hashName: "",
  headingSx: {},
  imageSx: {},
  sectionSx: {},
  sectionTitle: "",
  sectionTitleSx: {},
};

export default CardsGrid;
